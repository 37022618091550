import react from "react";
import * as prismic from "@prismicio/client";
import { Box, Flex, Grid, Image, Text } from "@chakra-ui/react";
import { Content } from "@prismicio/client";
import { CheckIcon } from "@/icons/check-icon";
import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import styles from "./CourseContent.module.css";
import { AppConfig } from "@/utils/AppConfig";
import FadeInWhenVisible from "@/components/FadeInWhenVisible/FadeInWhenVisible";

/**
 * Props for `CourseContent`.
 */
export type CourseContentProps =
  SliceComponentProps<Content.CourseContentSlice>;

/**
 * Component for "CourseContent" Slices.
 */
const CourseContent = ({ slice }: CourseContentProps): JSX.Element => {
  const { showlogo, title, content, footer_title, footer_subtitle } =
    slice.primary;

  return (
    <Box className={styles.courseContentWrapper}>
      <FadeInWhenVisible>
        <Box className="container">
          {showlogo && (
            <Flex textAlign={"center"} justifyContent={"center"}>
              <Image
                opacity={0.3}
                width="100px"
                marginBottom="40px"
                src="/eca-logo.png"
                alt={`${AppConfig.site_name} Logo`}
              />
            </Flex>
          )}

          <Text textStyle="h2" as="h2" color="white" marginBottom="20px">
            {prismic.asText(title)}
          </Text>

          <PrismicRichText field={content} />

          {slice.items.length > 0 && (
            <Box className={styles.courseContent__gridWrapper}>
              <Grid className={styles.courseContent__grid}>
                {slice.items.map((item, index) => (
                  <Flex alignItems="center" key={index}>
                    <Box
                      flexShrink={0}
                      marginRight="20px"
                      border="1px solid white"
                      width="30px"
                      height="30px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CheckIcon
                        fontSize="16px"
                        color="brand.white"
                        fill="brand.white"
                      />
                    </Box>
                    <Text
                      textStyle="p"
                      as="p"
                      color="white"
                      margin="0"
                      textAlign="left"
                    >
                      <b>{prismic.asText(item.list_item)}</b>
                    </Text>
                  </Flex>
                ))}
              </Grid>
            </Box>
          )}

          {prismic.asText(footer_title) && (
            <Box
              padding="5px 40px"
              background="#ffffff40 !important"
              width="auto"
              display="inline-block"
              margin="50px 0 20px 0"
            >
              <Text
                textStyle="h3"
                as="h3"
                color="white"
                margin="0"
                fontSize="24px"
              >
                {prismic.asText(footer_title)}
              </Text>
            </Box>
          )}

          {prismic.asText(footer_subtitle) && (
            <Text textStyle="p" as="p" color="white" margin="0">
              <b>{prismic.asText(footer_subtitle)}</b>
            </Text>
          )}
        </Box>
      </FadeInWhenVisible>
    </Box>
  );
};

export default CourseContent;
